<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <v-footer padless class="footer" height="10%" :style="{background: $vuetify.theme.themes.footer.background}">
        <v-card flat tile class="text-white py-2 px-2 text-center" height="100%" width="100%" :style="{background: $vuetify.theme.themes.footer.background}">
            <v-row class="footerRow">
                <v-col cols="24" sm="4">
                    <v-card-text class="pt-1">
                        <h5>Developed By</h5>
                        <h3>Technovation</h3>
                    </v-card-text>
                </v-col>
                <v-col cols="24" sm="4">
                    <v-card-text class="pt-1">
                        <h5>Copyright By</h5>
                        <h3>Myanmar Naing Group</h3>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </v-footer>
</template>
<script>
export default {

}
</script>
<style scoped>
    .footer {
        position: absolute;
        width: 100%;
        top: 60rem;
    }

    .footerRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    h5 {
        font-size: .8rem;
    }

    h4 {
        font-size: .5rem;
    }
</style>