<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <v-app-bar app color="#012754" light flat class="px-8; pt-4">
        <v-img src="../assets/MNGLogo_01.png"></v-img>
        <v-spacer></v-spacer>
        <v-btn text @click="scroll('HomeView')">Home</v-btn>
        <v-btn text @click="scroll('AboutView')">About Us</v-btn>
        <v-btn text @click="scroll('VisionView')">Vision & Goals</v-btn>
        <v-btn text @click="scroll('BusinessView')">Business Units</v-btn>
        <v-btn text @click="scroll('CompaniesView')">Sister Companies</v-btn>
        <v-btn text @click="scroll('ContactView')">Contact Us</v-btn>
    </v-app-bar>
</template>
<script>
export default {
    methods: {
        scroll(refName) {
            const element = document.getElementById(refName);
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
}
</script>
<style>
    
</style>