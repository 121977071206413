<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-app id="HomeView" :style="{ background: $vuetify.theme.themes.light.background }">
    <Navbar />
    <v-container fluid class="heroContainer">
      <v-row>
        <v-col cols="6">
          <h3 :style="{ color: $vuetify.theme.themes.heroText.color }" dark class="heroText">Myanmar Naing Group</h3>
          <p class=" heroPara">
            MNG Group of companies is originally established as Myanmar Naing Group Co.,Ltd. <br />Since 2005, Our Head
            quarter
            is located at the strategic place: No.5, Natural Park <br /> Residence, Mindama Street, Mayangone Township,
            Yangon,
            Myanmar.
          </p>
        </v-col>
        <v-col cols="12" class="padd">
          <div>
            <v-row>
              <v-col cols="12">
                <div class="first">
                  <v-icon color="#2563AD" class="ml-3 pb-2 cardIcon">
                    mdi-bed
                  </v-icon>
                  <h4>HOTELS AND TOURISM</h4>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="second bgColor1">
                  <v-icon color="#f3be0e" class="ml-3 pb-2 cardIcon">
                    mdi-fuel
                  </v-icon>
                  <h4>FUEL SUPPLY CHAIN</h4>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="third bgColor2">
                  <v-icon color="#f3be0e" class="ml-3 pb-2 cardIcon">
                    mdi-truck
                  </v-icon>
                  <h4>LOGISTICS</h4>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="fourth bgColor3">
                  <v-icon color="#7c6108" class="ml-3 pb-2 cardIcon">
                    mdi-factory
                  </v-icon>
                  <h4>MINING AND CEMENT FACTORY</h4>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div class="bodyPara">
            <h3 :style="{ color: $vuetify.theme.themes.headerText.color }">About Us</h3>
            <p>
              MNG Group of companies is originally established as Myanmar Naing Group Co.,Ltd, since 2005. Our Head
              quarter is located at the strategic<br /> place:No.5, Natural Park Residence, Mindama Street, Mayangone
              Township,
              Yangon, Myanmar.<br /><br /> It was founded based on existing business operations alliance with sister
              companies
              and partner companies to fulfill the requirements of each<br /> participant and to achieve a better positive
              synergetic results.
            </p>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="bodyPara2">
            <h3 :style="{ color: $vuetify.theme.themes.headerText.color }">Vision and Goals</h3>
            <p>
              To become a regional competitive and a socially responsible cooperate promotion and contributing the multi
              level development of the nation to<br /> be comparable to other ASEAN countries.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </v-app>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import Navbar from '../components/Navbar.vue';
import Footer from '../components/Footer.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    Navbar,
    Footer,
  },
});
</script>

<style>
  .heroContainer {
    background-color: #012754;
    width: 100%;
    height: 35%;
    padding-top: 400px;
  }

  .heroText {
    position: absolute;
    left: 20rem;
    top: 12rem;
  }

  .heroPara {
    position: absolute;
    left: 20rem;
    top: 14rem;
    color: #fefefe;
  }

  .first {
    position: absolute;
    top: 22rem;
    left: 14rem;
    width: 15rem;
    height: 15rem;
    background: linear-gradient(
      to top,
      #faf6f6,
      #faf6f6 70%,
      #ebebeb 50%,
      #f3f2f2 30%
    );
    text-align: left;
    padding: 2rem 2rem;
  }

  .second {
    position: absolute;
    top: 22rem;
    left: 30rem;
    width: 15rem;
    height: 15rem;
    text-align: left;
    padding: 2rem 2rem;
  }

  .third {
    position: absolute;
    top: 22rem;
    left: 46rem;
    width: 15rem;
    height: 15rem;
    text-align: left;
    padding: 2rem 2rem;
  }

  .fourth {
    position: absolute;
    top: 22rem;
    left: 62rem;
    width: 15rem;
    height: 15rem;
    text-align: left;
    padding: 2rem 2rem;
  }

  .bgColor1 {
    background-color: #0b76f8;
  }

  .bgColor2 {
    background-color: #4494f7;
  }

  .bgColor3 {
    background-color: #87bbfa;
  }

  .cardIcon {
    font-size: xx-large;
  }

  .bodyPara {
    position: absolute;
    top: 40rem;
    left: 16rem;
  }

  .bodyPara2 {
    position: absolute;
    top: 52rem;
    left: 16rem;
  }
</style>
